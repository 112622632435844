import "core-js/modules/es.array.push.js";
import { paymentData } from '@api/expense';
export default {
  name: 'paymentDetail',
  components: {},
  props: {},
  data() {
    return {
      paymentData: {},
      merOrderId: '',
      disabled: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getPaymentData();
  },
  mounted() {},
  methods: {
    getPaymentData() {
      let url = window.location.href;
      this.merOrderId = url.substring(url.indexOf('=') + 1, url.length);
      paymentData({
        order: this.merOrderId
      }).then(res => {
        let result = res.data;
        if (result.code === '10000') {
          this.paymentData = result.data;
          if (this.paymentData.notifyStatus === 'TRADE_SUCCESS') {
            this.disabled = true;
          }
        }
      });
    },
    handleClickPay() {
      console.log('12');
      let {
        merOrderId
      } = this;
      let {
        totalAmount
      } = this.paymentData;
      this.$router.push({
        name: 'paymentIndex',
        query: {
          erjiaOderId: merOrderId,
          erjiaTotalAmount: totalAmount
        }
      });
    }
  }
};